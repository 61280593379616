h1,
p {
  font-family: Lato;
}

.gutter {
  background: rgba(255, 255, 255, 0.05);

}

.gutter-horizontal {
  cursor: ew-resize;
}

.gutter-vertical {
  cursor: ns-resize;
}

.comp {

  color: white;
  height: 87.6vh;

}

.comp2 {

  color: white;
  height: 87.6vh;
  display: flex;

}

.wrap {
  display: flex;
}

.desktop-height1 {
  height: calc(100vh - 111px);
}

.desktop-height2 {
  height: calc(100vh - 145px);
}

@media (max-width: 768px) {
  .desktop-height1 {
    height: calc(55vh - 20px);
  }

  .desktop-height2 {
    height: calc(50vh - 20px);
  }

  .comp2 {
    height: 50.6vh;

  }
}